#fleche{
	font-size: 20px;
	padding-top: 120px;
	padding-left: 15px
}
.boxLeft{
	float:left;
	width: 350px;
	margin-top: 25px;
	margin-bottom: 25px
}

.boxRight{
	float: right;
	margin-right: 40px;
	width: 535px;
	margin-top: 25px;
	margin-bottom: 25px
}

hr{
	clear: both
}

#texteAppli{
	width: 500px
}

#nos-realisaions .pic{
	height: 197px
}

#nos-realisaions .roboto-bold{
	font-size: 16px
}

.main-construction img{
	display: block;
	margin-left: auto;
	margin-right: auto
}

.zone-gris-leger{
	padding: 4px 0px; 
	border: none;
	background: -webkit-linear-gradient(-90deg, #E9E9EB 0, #B0B1B3 100%);
  	background: -moz-linear-gradient(180deg, #E9E9EB 0, #B0B1B3 100%);
  	background: linear-gradient(180deg, #E9E9EB 0, #B0B1B3 100%);
  	text-decoration: none;
}

.zone-formulaire-permis{
	background: url("/img/formulaire-permis.png") no-repeat right top;
}

.zone-formulaire-pro-1{
	background: url("/img/bandeau-pro-offre-1.png") no-repeat right top
}

.zone-formulaire-pro-2{
	background: url("/img/bandeau-pro-offre-2.png") no-repeat right top
}

#container-realisation h2, #container-realisation h3{
	font-size: 18px
}

.zone-img-gratuit img{
	margin-left: auto;
	margin-right: auto;
}